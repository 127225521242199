<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-row>
          <b-col cols="12" md="6" xl="4" class="mb-2">
            <h6>No SPPD</h6>
            <p class="card-text mb-25">
              {{ currentSppd.sppd_number || '-' }}
            </p>
          </b-col>
          <b-col cols="12" md="6" xl="4" class="mb-2">
            <h6>{{ employee.type === 'pegawai' ? 'NIPPOS' : 'NIK' }} / Nama</h6>
            <p class="card-text mb-25">
              {{ employee.type === 'pegawai' ? employee.nippos : employee.nik }}
              /
              {{ employee.type === 'pegawai' ? employee.nama : employee.name }}
            </p>
          </b-col>
          <!-- v-if="sppd.submitted_date" -->
          <b-col cols="12" md="6" xl="4" class="mb-2">
            <h6>Tanggal Diajukan</h6>
            <p class="card-text mb-25">
              {{ currentSppd.submission_date || '-' | moment('DD MMMM YYYY') }}
            </p>
          </b-col>
          <b-col cols="12" md="6" xl="4" class="mb-2">
            <h6>Jenis Fasilitas</h6>
            <p class="card-text mb-25">
              {{ sppdFacilityType }}
            </p>
          </b-col>
          <b-col cols="12" md="6" xl="4" class="mb-2">
            <h6>Jabatan</h6>
            <p class="card-text mb-25">
              {{
                employee.type === 'pegawai'
                  ? employee.descjabatan
                  : employee.position
              }}
            </p>
          </b-col>
          <b-col cols="12" md="6" xl="4" class="mb-2">
            <h6>Bagian/Unit Kerja</h6>
            <p class="card-text mb-25">
              {{ employee.namabagian || '-' }}
            </p>
          </b-col>
          <b-col cols="12" md="6" xl="4" class="mb-2">
            <h6>Nomor Rekening</h6>
            <p class="card-text mb-25">{{ kode_rekening }}</p>
          </b-col>
          <b-col cols="12" md="6" xl="4" class="mb-2">
            <h6>Status</h6>
            <p class="card-text mb-25">
              {{ currentSppd.status_paid_detail || '-' }}
            </p>
          </b-col>
          <b-col cols="12" class="pl-2 mb-2 text-right">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="secondary"
              :disabled="loadingPrint"
              @click="print"
            >
              Cetak
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mb-3">
            <b-table
              striped
              hover
              responsive
              show-empty
              :items="items"
              :fields="fields"
            >
              <template #cell(no)="data">{{ data.index + 1 }}</template>

              <template #cell(type)="data">{{
                capitalizing(data.item.type)
              }}</template>

              <template #cell(total)="data">
                {{ data.item.total | currency }}
              </template>

              <template #cell(status)="data">
                <span
                  v-if="
                    data.item.status === null ||
                    (data.item.type === 'uang harian' && !data.item.status)
                  "
                  >Menunggu Approval</span
                >
                <span v-else-if="data.item.status == 1" class="text-success">
                  Disetujui
                </span>
                <span v-else class="text-danger">Ditolak</span>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="pl-2 mb-2">
            <h6 class="mb-25">Jumlah yang Dibayarkan</h6>
            <p class="card-text mb-25">{{ totalCost | currency }}</p>
          </b-col>
          <b-col cols="12" class="pl-2 mb-2">
            <p class="mb-25">Mengetahui</p>
            <div v-if="currentSppd.approval_payment_admin">
              <p class="card-text mb-25">
                {{ currentSppd.approval_payment_admin.employee || '-' }} /
                {{ currentSppd.approval_payment_admin.name || '-' }} /
                {{ currentSppd.approval_payment_admin.position || '-' }}
              </p>
              <p class="font-italic" style="font-size: 12px">
                {{
                  currentSppd.approval_payment_admin.created_at
                    | moment('DD/MM/YYYY HH:mm:ss')
                }}
              </p>
            </div>
            <p v-else>-</p>
          </b-col>
          <b-col cols="12" class="pl-2 mb-2">
            <p class="mb-25">Yang Membayar</p>
            <div v-if="currentSppd.approval_payment_superior">
              <p class="card-text mb-25">
                {{ currentSppd.approval_payment_superior.employee || '-' }} /
                {{ currentSppd.approval_payment_superior.name || '-' }} /
                {{ currentSppd.approval_payment_superior.position || '-' }}
              </p>
              <p class="font-italic" style="font-size: 12px">
                {{
                  currentSppd.approval_payment_superior.created_at
                    | moment('DD/MM/YYYY HH:mm:ss')
                }}
              </p>
            </div>
            <p v-else>-</p>
          </b-col>
          <b-col cols="12" class="pl-2 mb-2">
            <p class="mb-25">Yang Menerima</p>
            <p class="card-text mb-25">
              {{
                employee.type === 'non-pegawai'
                  ? `${employee.nik || '-'} / ${employee.name || '-'} / ${
                      employee.position || '-'
                    }`
                  : `${employee.nippos || '-'} / ${employee.nama || '-'} / ${
                      employee.descjabatan || '-'
                    }`
              }}
            </p>
          </b-col>
        </b-row>
        <b-card-footer>
          <!-- back -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="float-right"
            variant="outline-secondary"
            :to="{ name: 'bukti-bayar' }"
          >
            Kembali
          </b-button>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import { BRow, BCol, BButton, BCard, BCardFooter, BTable } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { heightFade } from '@core/directives/animations'

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BButton,
    BTable,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      name: 'BuktiBayarList',
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: '/payments',
        redirect: 'bukti-bayar',
      },
      currentSppd: {},
      fields: [
        'no',
        { key: 'type', label: 'Komponen Biaya' },
        { key: 'note', label: 'Rincian' },
        { key: 'total', label: 'Total' },
        { key: 'status', label: 'Status' },
      ],
      items: [],
      totalCost: 0,
      messages: '',
      loadingPrint: false,
    }
  },
  computed: {
    sppdFacilityType() {
      if (this.currentSppd.sppd_facility_type_id == 1) {
        return 'Perjalanan Dinas Biasa'
      }
      if (this.currentSppd.sppd_facility_type_id == 2) {
        return 'Perjalanan Dinas Fasilitas Khusus'
      }
      return '-'
    },
    employee() {
      return this.currentSppd.employee?.raw
        ? JSON.parse(this.currentSppd.employee.raw)
        : {}
    },
    kode_rekening() {
      return this.currentSppd.employee?.no_rek || '-'
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      axios.get(`${this.config.api}/${this.$route.params.id}`).then(res => {
        const { data } = res.data
        this.currentSppd = data
        this.items = data.payments
        this.totalCost = data.total_payment
      })
    },
    print() {
      this.loadingPrint = true
      axios
        .get(`payments/${this.currentSppd.id}/print`, {
          responseType: 'blob',
        })
        .then(res => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: 'application/pdf' }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `Bukti-Pembayaran-${this.currentSppd.sppd_number}.pdf`,
          )
          document.body.appendChild(link)
          this.loadingPrint = false
          link.click()
          link.remove()
        })
        .catch(() => {
          this.loadingPrint = false
          // console.error(error)
        })
    },
    capitalizing(text) {
      let words = text.toLowerCase().split(' ')
      words = words
        .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
        .join(' ')
      return words
    },
  },
}
</script>
